
import Button from "@/components/Button.vue";
import Loading from "@/components/Loading.vue";
import Switch from "@/components/ToogleSwitch.vue";
import GestorExamDTO from "@/dtos/gestor/GestorExamDTO";
import { maskCpf } from '@/utils/stringFunctions';
import { PropType, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import ExamResultStatus from './ExamResultStatus.vue';

const FinishedExamCard = defineComponent({
    components: { Switch, ExamResultStatus, Button, Loading },
    props: {
        exam: {
            type: Object as PropType<GestorExamDTO>,
            required: true
        },
        printing: {
            type: Boolean,
            required: true,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    setup(props, context) {
        const certificateGivenChecked = ref<boolean>(props.exam?.certificateGivenAt != null);
        const router = useRouter();

        const printCertificate = () => {
            context.emit("printCertificate", props.exam);
        };

        const startAudit = () => {
            router.replace(`/exames/${props.exam.metadata!.schedulingId.toString()}`);
        };

        watch(() => certificateGivenChecked.value, (newValue) => {
            if (newValue) {
                context.emit("certificateGivenAtChange", new Date().toISOString());
            }
            else {

                context.emit("certificateGivenAtChange", null);
            }
        });

        return { maskCpf, certificateGivenChecked, printCertificate, startAudit };
    },

});

export default FinishedExamCard;
