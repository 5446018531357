
import { defineComponent } from 'vue';

const Loading = defineComponent({
    props: {
        color: {
            type: String,
            default: 'white'
        },
        size: {
            type: Number,
            default: 6
        }
    }
});

export default Loading;
