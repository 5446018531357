import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      style: { backgroundColor: _ctx.color, width: `${_ctx.size/16}rem`, height: `${_ctx.size/16}rem` },
      class: "vs-loading__dot"
    }, null, 4),
    _createVNode("div", {
      style: { backgroundColor: _ctx.color, width: `${_ctx.size/16}rem`, height: `${_ctx.size/16}rem` },
      class: "vs-loading__dot"
    }, null, 4),
    _createVNode("div", {
      style: { backgroundColor: _ctx.color, width: `${_ctx.size/16}rem`, height: `${_ctx.size/16}rem` },
      class: "vs-loading__dot"
    }, null, 4)
  ]))
}