
import { defineComponent, PropType } from 'vue';

const Button = defineComponent({
    props: {
        type: {
            type: String as PropType<'primary' | 'hollow'>,
            default: 'primary'
        }
    }
});

export default Button;
