import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "print-page bg-white shadow-lg mb-8 last:mb-0 print:shadow-none print:mb-0 mx-auto" }
const _hoisted_2 = { class: "w-full border-collapse" }
const _hoisted_3 = { class: "p-0 relative z-50" }
const _hoisted_4 = { class: "p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("table", _hoisted_2, [
      _createVNode("thead", null, [
        _createVNode("tr", null, [
          _createVNode("td", _hoisted_3, [
            _renderSlot(_ctx.$slots, "header")
          ])
        ])
      ]),
      _createVNode("tbody", null, [
        _createVNode("tr", null, [
          _createVNode("td", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ]),
      _createVNode("tfoot", null, [
        _createVNode("tr", null, [
          _createVNode("td", null, [
            _renderSlot(_ctx.$slots, "footer")
          ])
        ])
      ])
    ])
  ]))
}