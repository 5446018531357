
import vm from "@/viewModels/MainViewModel";
import { defineComponent } from "vue";
import CertificateCEView from "./CertificateCEView.vue";

const CertificateReportViewer = defineComponent({
    components: { CertificateCEView },
    setup() {
        return { vm };
    }
});

export default CertificateReportViewer;
