
import Icon from "@/components/Icon.vue";
import PrintPage2 from '@/components/PrintPage2.vue';
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import GestorExamDTO from "@/dtos/gestor/GestorExamDTO";
import { GroupTypeQuestion } from "@/dtos/reports/CalculateResultGroupTypeQuestionDTO";
import { useAlert } from '@/hooks/alert';
import ExameService from "@/services/ExameService";
import ReportService from "@/services/ReportService";
import vm from "@/viewModels/MainViewModel";
import { defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";

interface CertificateState {
    calcs: GroupTypeQuestion[];
    QRCodeUrl: string;
}


const CertificateCEView = defineComponent({
    components: { PrintPage2, Icon },
    setup(_props, context) {
        const { replace } = useRouter();
        const alert = useAlert();
        const exam = JSON.parse(localStorage.getItem('certificate')!) as GestorExamDTO;
        const examQuestions = ref<ExamResponseDTO>();

        const dateObject = new Date(exam.schedulingDate ?? new Date());

        const day = String(dateObject.getUTCDate()).padStart(2, '0');
        const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
        const year = dateObject.getUTCFullYear();


        const state = reactive<CertificateState>({
            calcs: [],
            QRCodeUrl: ''
        });

        const onLoadDispatch = () => {
            const message = { type: 'onLoad', content: '' };
            window.parent.postMessage(message, '*');
        };

        const onLoadErrorDispatch = (errorMessage: string) => {
            const message = { type: 'onLoadError', content:  errorMessage };
            window.parent.postMessage(message, '*');
        };

        const afterPrint = () => {
            context.emit('afterPrint');
        };

        const loadReport = async () => {
            try {
                const [request] = ReportService.CalculateResultGroupTypeQuestion(exam.metadata!.schedulingId);
                const [examAdminPromise] = ExameService.GetExameGestor(exam.metadata!.schedulingId);

                const [resp, examAdmin] = await Promise.all([request, examAdminPromise]);

                state.calcs = resp;
                examQuestions.value = examAdmin;

                await nextTick();
            } catch (error) {
                onLoadErrorDispatch(error?.toString() ?? "");
                alert({
                    title: "Não foi possivel carregar o resultado",
                    message: error.message,
                    actions: [{ title: "Voltar", action: () => replace('exams') }],
                });
            }
        };

        watch([() => state.calcs, () => examQuestions.value], async ([newCalcs, newExamQuestions]) => {
            if (newCalcs.length > 0 && newExamQuestions) {
                await nextTick();
                setTimeout(() => {
                    window.print();
                    onLoadDispatch();
                }, 3000);
            }
        });        

        onMounted(async () => {
            window.addEventListener('afterprint', afterPrint);
            await loadReport();
        });

        onUnmounted(async () => {
            window.removeEventListener('afterprint', afterPrint);
        });


        return { state, print, exam, day, month, year, examQuestions, vm };
    },
});

export default CertificateCEView;
