
import { defineComponent } from 'vue';

const ToogleSwitch = defineComponent({
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        activeColor: {
            type: String,
            default: 'var(--primary-color)'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const clicked = () => {
            if(!props.disabled) {
                context.emit("update:modelValue", !props.modelValue);
            }
        };

        return { clicked };
    }
});

export default ToogleSwitch;
