

import { computed, defineComponent } from "vue";

interface Color {
    textColor: string;
    bgColor: string;
    borderColor: string;
}

const ExamResultStatus = defineComponent({
    components: {  },
    props: {
        resultName: {
            type: String,
            required: true
        },
        resultDescription: {
            type: String,
            required: true
        }
    },
    setup(props) {

        const color = computed((): Color => {
            if (props.resultName === 'disqualified') {
                return { textColor: '#E54F45', bgColor: '#ffffff', borderColor: '#E54F45' };
            }
            if (props.resultName === 'approved') {
                return { textColor: '#04986D', bgColor: '#04986D33', borderColor: '' };
            }
            if (props.resultName === 'reproved') {
                return { textColor: '#804440', bgColor: '#FE938C', borderColor: '' };
            }
            if (props.resultName === 'in_analysis') {
                return { textColor: '#66633C', bgColor: '#E8E288', borderColor: '' };
            }
            return { textColor: '#333333', bgColor: '#ffffff', borderColor: '#d3d3d3' };
        });

        const text = computed(() => {
            return props.resultDescription;
        });

        return { text, color };



    },

});

export default ExamResultStatus;
